import  {useState, useEffect, useRef,Suspense, lazy} from 'react'
import {useNavigate} from 'react-router-dom'
import  { getParsedLocalStorageItem } from '../../components/Navbar/Navbar'
import { Helmet } from 'react-helmet-async';
import InfiniteScroll from 'react-infinite-scroll-component'
// import { Card, Row, Col, Button, Image, Pagination, Carousel} from 'react-bootstrap'
import welcome from '../../assets/welcome-hand.png'

import vsBanner from '../../assets/vs-banner.png'
import immediate from '../../assets/immediate-delivery.png'
import immediateMobile from '../../assets/immediate-mobile.png'
import homeBg from '../../assets/home-bg.png'
import leaf from '../../assets/leaf.svg'
import basket from '../../assets/basket-small.svg'
import fire from '../../assets/fire.svg'

import subBox from '../../assets/subscription-box-2.png'
import premium from '../../assets/virtue-premium.png'
import voucher from '../../assets/voucher.png'
import foodBundles from '../../assets/food-bundles.svg'
import subBoxMobile from '../../assets/subbox--mobile.svg'
import voucherMobile from '../../assets/voucher-mobile.png'
import { useAppContext } from '../../AppContext'
import Carousel from 'react-bootstrap/Carousel'
import Pagination from 'react-bootstrap/Pagination'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'


import { localStorageGetItem, localStorageSetItem } from '../../App';
import orderRoute from '../../services/orderRoute';
import { trackAddToCart, trackAddToWishlist } from '../../services/tracking';
import LazyLoad from 'react-lazyload';

import productRoute from '../../services/productRoute'
import { trackEvent } from '../../mixpanel'





export const sanitizeProductName = (name) => {
  return name?.toLowerCase().replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
    .replace(/^-+|-+$/g, '');    // Remove leading or trailing hyphens
};



const Home = () => {
 const {showToast, setWishlistItems,cartItems, setCartItems,updateCart, setApiWishlistItems, apiWishlistItems,isMobile} = useAppContext();
  const [loading, setLoading] = useState(false);
  
  const {getProducts, getProductCategory, getMoreProducts, filterProductsByCategory, getGlobalProducts, getMobileProducts,}= productRoute();
  const {updateWishlist, getWishlist}=orderRoute();
 
  const [activeCategories, setActiveCategories] = useState({ categoryName: 'All' });
  const [trendingProducts, setTrendingProducts] = useState([]);

  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 20,
    totalPages:0,
    totalProducts:0,
   
  });

  const [hasMore, setHasMore] = useState(true);
  const [viewAll, setViewAll] = useState(false);

 
 
 const navigate = useNavigate();


  const [products, setProducts] = useState([]);
  const [globalProducts, setGlobalProducts] = useState([]);
  const userData = getParsedLocalStorageItem('userData');
  const userToken = localStorageGetItem
('userToken');
const isLoggedIn = !!userData & !!userToken;
const [filledHearts, setFilledHearts] = useState({});
 const [isPaused, setIsPaused] = useState(false);
//  const [scrollPosition, setScrollPosition] = useState(0);
//  const [maxScrollLeft, setMaxScrollLeft] = useState(0);
 const scrollContainerRef = useRef(null);
  const handleImageClick = () => {
    setIsPaused(!isPaused);
  };



  useEffect(() => {
    
    const startTime = Date.now();
    
    const handleUnload = () => {
      const endTime = Date.now();
      const timeSpent = endTime - startTime;
      
      trackEvent('Landing Page', {
        property: 'number_of_daily_visits',
        'User': userData?.email || 'Guest',
        time_spent_on_application: timeSpent, // time spent in milliseconds
      });
    };
  
    window.addEventListener('beforeunload', handleUnload);
  
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, []);

//   useEffect(()=>{
   
//     getTrendingProducts(
//        showToast, setTrendingProducts, 
     
//     )
  
// },[])
  
  useEffect(() => {
    

    getProductCategory(
      setLoading, showToast, setCategories, setAllCategories
     
    )

    
  }, []); 

  const handleScroll = (direction) => {
    const container = scrollContainerRef.current;
    const scrollAmount = direction === 'left' ? -container.clientWidth * 0.8 : container.clientWidth * 0.8;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  // const checkScrollPosition = () => {
  //   const container = scrollContainerRef.current;
  //   setScrollPosition(container?.scrollLeft);
  //   setMaxScrollLeft(container?.scrollWidth - container?.clientWidth);
  // };

  // useEffect(() => {
  //   const container = scrollContainerRef.current;
  //   container?.addEventListener('scroll', checkScrollPosition);
  //   checkScrollPosition(); // Initial check
  //   return () => container?.removeEventListener('scroll', checkScrollPosition);
  // }, [trendingProducts]);

  useEffect(() => {
    if (isMobile) {
    getMobileProducts(
      setLoading, 
      showToast, 
      products,
      setProducts,
      setTrendingProducts,
      pagination, 
      setPagination,
      
    );}
    
  }, [isMobile]); 


  useEffect(() => {
    if (!isMobile) {
      
      const fetchWithPagination = () => {
        getProducts(
          setLoading, 
          showToast, 
          setProducts,
          setTrendingProducts,
          pagination, 
          setPagination, 
          
        );
      };
  
      fetchWithPagination();
    }
   
  }, [pagination.currentPage, isMobile]); 
  
  // useEffect(() => {
  //   if (isMobile) {
  //   getMobileProducts(
  //     setLoading, 
  //     showToast, 
  //     products,
  //     setProducts,
  //     pagination, 
  //     setPagination,
      
  //   );}
    
  // }, []); 


  // useEffect(() => {
  //   if (!isMobile) {
      
  //     const fetchWithPagination = () => {
  //       getProducts(
  //         setLoading, 
  //         showToast, 
  //         setProducts,
  //         pagination, 
  //         setPagination, 
          
  //       );
  //     };
  
  //     fetchWithPagination();
  //   }
   
  // }, [pagination.currentPage]); 

  useEffect(()=>{
   
      getGlobalProducts(
        setLoading, showToast, setGlobalProducts, 
       
      )
    
  },[])


  


  const fetchMoreProducts = () => {
    if (pagination.currentPage < pagination.totalPages) {
      
        // Start loading more products
        getMoreProducts(
           setLoadingMore,
            showToast,
            setProducts,
            { ...pagination, currentPage: pagination.currentPage + 1 },
            setPagination,
            // Pass setLoadingMore to stop loading more products
        );
    } else {
        setHasMore(false); // No more products to fetch
    }
};
 


  
  useEffect(() => {
    if (isLoggedIn) {
      // Fetch the wishlist if logged in
      getWishlist(setLoading, showToast, setApiWishlistItems);
    }
  }, [isLoggedIn]);
 
  useEffect(() => {
    let wishlist = [];
  
    if (isLoggedIn) {
      // If logged in, use wishlist items fetched from API
      wishlist = apiWishlistItems;
      const updatedWishlistStatus = {};
  products.forEach(product => {
    const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.product?.id === product.id);
    updatedWishlistStatus[product.id] = isInWishlist;
  });
  setFilledHearts(updatedWishlistStatus);
    } else {
      // If not logged in, retrieve wishlist items from local storage
      const wishlist = JSON.parse(localStorageGetItem
('wishlistItems')) || [];
    
      const updatedWishlistStatus = {};
  products.forEach(product => {
    const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.product?.id === product.id);
    updatedWishlistStatus[product.id] = isInWishlist;
  });
  setFilledHearts(updatedWishlistStatus);
    }
  
   
  
  }, [products, apiWishlistItems]);
  
  useEffect(() => {
    const filterProducts = () => {
      if (activeCategories.categoryName === 'All') {
 
        setFilteredCategories(products);
      } else if (activeCategories.categoryName === 'Trending') {
        setFilteredCategories(trendingProducts)
      //  getTrendingProducts(
      //   showToast,
      //   setTrendingProducts,
      //   setFilteredCategories
      // )
      } else {
 
      const categoryId= activeCategories.id;

        filterProductsByCategory(categoryId, setFilteredCategories, showToast);

      }
    };
    
    filterProducts();
  }, [activeCategories, products]);
  

  let marginBottomStyle = {};
  if (filteredCategories?.length === 0) {
    marginBottomStyle = { marginBottom: '305px' };
  }else if (filteredCategories?.length === 1) {
    marginBottomStyle = { marginBottom: '290px' };
  } else if (filteredCategories?.length === 2) {
    marginBottomStyle = { marginBottom: '290px' };
  }else if (filteredCategories?.length === 3) {
    marginBottomStyle = { marginBottom: '80px' };
   } else if (filteredCategories?.length === 4) {
      marginBottomStyle = { marginBottom: '60px' };
  }
  // Handler for category change
  const handleCategoryChange = (category) => {
    setActiveCategories(category); 
    const eventName = `${category.categoryName} Category Viewed`;
    trackEvent(eventName, {
      'Category Name': category.categoryName,
      'User': userData?.email || 'Anonymous',
    });
  };
  useEffect(() => {
    if (selectedProduct) {
      const updatedProduct = cartItems.find(product => product.id === selectedProduct.id);
      if (updatedProduct && updatedProduct.quantity !== selectedProduct.quantity) {
        setSelectedProduct(updatedProduct);
      }
    }
  }, [cartItems, selectedProduct]);
  
  

  
  
  const displayCategories = viewAll ? categories : categories?.slice(0, 6);


const handleIncreaseQuantity = (id, increment = 1, replace) => {
  let newCartItems = [...cartItems];
  const cartIndex = newCartItems.findIndex(item => item.id === id);

  // Find the product in the global products array
  const product = globalProducts.find(p => p.id === id);
  if (!product) {
      console.log('Error', `Product with ID ${id} not found`);
      return;
  }

  let initialQuantity = 0;
  if (cartIndex !== -1) {
    initialQuantity = newCartItems[cartIndex].quantity;
  }

  // Check if the product is already in the cart
  if (cartIndex !== -1) {
      // Check if increasing quantity exceeds available stock
      if (newCartItems[cartIndex].quantity + increment > product.quantityAvailable) {
          showToast({toastText:'This product has limited stock!', isSuccess:false});
          return;
      }
      // Update the quantity in the cart
      newCartItems[cartIndex].quantity = replace ? increment : newCartItems[cartIndex].quantity + increment;
  } else {
      // Check if adding this quantity would exceed available stock
      if (product.quantity + increment > product.quantityAvailable) {
          showToast({toastText:'This product has limited stock!', isSuccess:false});
          return;
      }
      // Add the product to the cart
      newCartItems.unshift({ ...product, quantity: increment });
  }

  // Update cart items state
  setCartItems(newCartItems);
  updateCart(newCartItems);

  // Update the product list with the new quantities
  const updateProductList = (productsList) => productsList.map(product => {
      if (product.id === id) {
          const newQuantity = replace ? increment : (product.quantity || 0) + increment;
          return { ...product, quantity: newQuantity };
      }
      return product;
  });

  setGlobalProducts(prevGlobalProducts => updateProductList(prevGlobalProducts));
  setProducts(prevProducts => updateProductList(prevProducts));

  // Track the event of adding a product to the cart
  const addedProduct = globalProducts.find(p => p.id === id);
  if (addedProduct) {
    if (initialQuantity === 0) {
      trackEvent(`${addedProduct.productName} Added to Cart`, {
        'Product ID': addedProduct.id,
        'Product Name': addedProduct.productName,
        'Category Name': addedProduct.category,
        'Quantity Added': increment,
        'User': userData?.email || 'Anonymous',
        'Location': 'Home page'
      });
      trackAddToCart(addedProduct.id, addedProduct.productName, addedProduct.price, 'Add to Cart', userData && userData) 
    } else {
      trackEvent(`${addedProduct.productName} Increased in Cart`, {
        'Product ID': addedProduct.id,
        'Product Name': addedProduct.productName,
        'Category Name': addedProduct.category,
        'New Quantity': newCartItems[cartIndex].quantity,
        'User': userData?.email || 'Anonymous',
        'Location': 'Home page'
      });
      trackAddToCart(addedProduct.id, addedProduct.productName, addedProduct.price, 'Increase Quantity in Cart', userData && userData) 
    }
  }
};

  const handleDecreaseQuantity = (id) => {
    const productBeforeUpdate = cartItems.find(item => item?.id === id);
   
    let newCartItems = [...cartItems];
    const cartIndex = newCartItems.findIndex(item => item.id === id);
  
    // Check if the product is in the cart
    if (cartIndex !== -1) {
      // Decrease quantity or remove item if quantity is 1
      if (newCartItems[cartIndex].quantity > 1) {
        newCartItems[cartIndex] = { ...newCartItems[cartIndex], quantity: newCartItems[cartIndex].quantity - 1 };
        trackEvent(`${newCartItems[cartIndex].productName} Quantity Decreased in Cart`, {
          'Product ID': newCartItems[cartIndex].id,
          'Product Name': newCartItems[cartIndex].productName,
          'Category Name': newCartItems[cartIndex].category,
          'New Quantity': newCartItems[cartIndex].quantity,
          'User': userData?.email || 'Anonymous',
          'Location': 'Home page'
        });
        trackAddToCart(newCartItems[cartIndex].id, newCartItems[cartIndex].productName, newCartItems[cartIndex].price, 'Decrease Quantity in Cart', userData && userData) 
      } else {
        // Remove item from cart if quantity is 1
        newCartItems.splice(cartIndex, 1);
       
        trackEvent(`${productBeforeUpdate.productName} Removed from Cart`, {
          'Product ID': productBeforeUpdate.id,
          'Product Name': productBeforeUpdate.productName,
          'Category Name': productBeforeUpdate.category,
          'Location': 'Home page',
          // Here, we know the quantity reached 0, leading to its removal
          'User': userData?.email || 'Anonymous',
        });
        trackAddToCart(productBeforeUpdate?.id, productBeforeUpdate?.productName, productBeforeUpdate?.price, 'Remove from Cart', userData && userData) 
    
      }
    } else {
        console.log('Error', `Product with ID ${id} not found in the cart`);
        return; // Exit function as there is no item to decrease
    }
  
    setCartItems(newCartItems);
  
    // Update product quantity in the products state
    const updatedProducts = products.map(product => {
      if (product.id === id) {
        let newQuantity = (product.quantity || 0) - 1;
        return { ...product, quantity: newQuantity < 0 ? 0 : newQuantity };
      }
      return product;
    });
  
    setProducts(updatedProducts);
  
    // Update cart storage or state
    updateCart(newCartItems); 
};

  

  const handlePageChange = (page) => {
    setPagination(prevState => ({
      ...prevState,
      currentPage: page
    }));
  };
  
  
  const filterProducts = (category) => {
    // setActiveCategory(category); 
    const eventName = `${category?.categoryName} Category Viewed`;
    trackEvent(eventName, {
      'Category Name': category?.categoryName,
      'User': userData?.email || 'Anonymous',
      
    });

 

    const categoryName = encodeURIComponent(
      category?.categoryName
        .replace(/&/g, 'and') // Replace '&' with 'and'
        .replace(/\s+/g, '-') // Replace spaces with hyphens
    );
     const categoryId = category?.sku;
    
    // Navigate with both categoryName and categoryId
    navigate(`/category/${categoryName}/${categoryId}`);
  };
  
  const handleShowProductModal = (product) => {

   
    trackEvent(`${product?.productName} Viewed`, {
      'Product ID': product?.id,
      'Product Name': product?.productName,
      'Category Name': product?.category,
      'User': userData?.email || 'Anonymous',
      // Any other relevant properties
    });
    const sanitizedProductName = sanitizeProductName(product?.productName);

  // Navigate to the product page with the sanitized product name in the URL
     const productId = product?.sku;

navigate(`/products/${sanitizedProductName}/${productId}`);
  };

  
  const toggleFill = (productId) => {



    if (isLoggedIn) {

      updateWishlist(
        productId,   
        showToast, 
        filledHearts,
        setFilledHearts,
        apiWishlistItems, 
        setApiWishlistItems, 
        products
      );
    }
     else {
      const newState = !filledHearts[productId];
      setFilledHearts(prevState => ({
        ...prevState,
        [productId]: newState,
      }));
  
      let wishlist = getParsedLocalStorageItem('wishlistItems') || [];
      const index = wishlist.findIndex(item => item?.product?.id === productId);
      let toastText = '';
      
      if (newState) {
        // Add to local wishlist if not already present
        if (index === -1) {
          const productToAdd = products.find(product => product.id === productId);
          wishlist = [{product:productToAdd}, ...wishlist]; // Prepend the new product
          toastText = 'Item added to Wishlist!';
        
        }
      } else {
        // Remove from local wishlist if present
        if (index !== -1) {
          wishlist = wishlist.filter(item => item?.product?.id !== productId);
          toastText = 'Item removed from Wishlist!';
        }
      }
  
      // Update local storage and state
      localStorageSetItem('wishlistItems', JSON.stringify(wishlist));
      setWishlistItems(wishlist);
  
      // Show the appropriate toast message
      showToast({
        toastText: toastText,
        isSuccess: true
      });
      const product = products.find(p => p.id === productId);
      trackEvent(newState ? `${product?.productName} Added to Wishlist` : `${product?.productName} Removed from Wishlist`, {
        'Product ID': productId,
        'Product Name': product?.productName,
        'Category Name': product?.category,
        'User': userData?.email || 'Anonymous',
     
      });
      
      trackAddToWishlist(productId, product.productName, newState ? 'Add to Wishlist' : 'Remove from Wishlist', userData && userData);
    }
  
  
    
  };

  const websiteStructuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Virtue's Shop",
    "url": "https://app.virtuesshop.co/",
    "potentialAction": [
      {
          "@type": "SearchAction",
          "target": "https://app.virtuesshop.co/search"
      },
      {
          "@type": "View Food Boxes",
          "target": "https://app.virtuesshop.co/subscription",
      },
      {
          "@type": "View Food Vouchers",
          "target": "https://app.virtuesshop.co/vouchers",
      },
    
    ]
};

const organizationStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Virtue's Shop",
    "url": "https://app.virtuesshop.co/",
    "logo": "https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link",  // Replace with actual logo URL
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+234 9167682871", 
        "contactType": "Customer Service",
        "areaServed": "NG",
        "availableLanguage": "en"
    },
    "sameAs": [
        "https://web.facebook.com/people/VirtuesShop/61556578874811/?mibextid=LQQJ4d",
        "https://www.instagram.com/virtuesshop_?igsh=cmcxazFqbTQwZmxx",
        "https://x.com/VirtuesShop?t=pVoMBdmEVgRNA6LEvm4A-Q&s=08e"
    ]
};

  // if (loading) {
  //   return <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
  //     <div className="spinner-border text-success" role="status">
  //       <span className="visually-hidden">Loading...</span>
  //     </div>
  //   </div>;
  // }

  return (
    <div>

<Helmet>
        <title>Virtue's Shop - Fresh Food Delivery in Lagos</title>
        <meta name="description" content="Taste the convenience of high quality and affordable farm fresh produce and packaged groceries delivered to your doorstep.
" />
        <meta name="keywords" content="Food, Foodstuffs, Fresh tomatoes, Fresh food, Fresh food market, Fresh food delivery, Foodstuff shop in Lagos, Foodstuff delivery shop in Lagos, Affordable food store" />
        <meta property="og:title" content="Virtue's Shop - Fresh Food Delivery in Lagos" />
        <meta property="og:description" content="Taste the convenience of high quality and affordable farm fresh produce and packaged groceries delivered to your doorstep.
" />
        <meta property="og:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta property="og:url" content="https://app.virtuesshop.co/" />
        <meta property="og:type" content="website" />


        <meta name="twitter:title" content="Virtue's Shop - Fresh Food Delivery in Lagos" />
        <meta name="twitter:description" content="Taste the convenience of high quality and affordable farm fresh produce and packaged groceries delivered to your doorstep.
" />
        <meta name="twitter:image" content="https://drive.google.com/file/d/1u57BUYQF0wV6DAA6rzbEYnR9n2i3pe-U/view?usp=drive_link" />
        <meta name="twitter:card" content="summary_large_image" />

        
        <meta name="author" content="Virtue's Shop - Fresh Food Delivery in Lagos" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://app.virtuesshop.co/" />

        <script type="application/ld+json">
                    {JSON.stringify(websiteStructuredData)}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(organizationStructuredData)}
                </script>

      </Helmet>
     
      {isMobile ? (<div>
        <div className='px-3 pt-3 py-5'  >
        
        {isLoggedIn && ( 
        <div className='d-flex justify-content-between align-items-center mb-2 pt-4 mt-5'>
          <div className='d-flex gap-1  align-items-center'>
  <h6 style={{fontWeight:'500',color:'#344054', letterSpacing:'-2%'}} className='my-auto'>Welcome {userData?.firstName} </h6><img src={welcome} alt='welcome to virtuesshop'style={{width:'21px', height:'23px'}}/>
</div>
 
</div>)}

{!isLoggedIn && (<>
 
        <div className='d-flex justify-content-between align-items-center pt-4 mt-5 mb-2'>
          <div className='d-flex gap-1  align-items-center'>
  <h6 style={{fontWeight:'500',color:'#344054', letterSpacing:'-2%'}} className='my-auto'>Welcome 👋</h6> 
</div>

</div>

</>)}
<section className='pt-2'>
<Carousel controls={false} indicators={true} interval={isPaused ? null : 3000} className='custom-carousel'>
     
      <Carousel.Item>
      <div className='pb-5 '>
        <img
          className="d-block w-100"
          src={immediateMobile}
          
          style={{ borderRadius: '15px', border: 'none' }} 
          alt="Immediate fresh food delivery"
          onClick={handleImageClick}
        />
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='pb-5 '>
      
     <Card style={{ borderRadius: '15px', border: 'none' }} className='position-relative' onClick={handleImageClick}>
  <Card.Img src={homeBg} alt='fresh food delivery' style={{ opacity: '', maxHeight: '130px', height: '100%', objectFit: 'cover', borderRadius: '15px' }} loading="lazy"/>
 
  <Card.ImgOverlay style={{ backgroundColor: 'rgba(90, 127, 72, 0.82)', position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1, borderRadius:'12px' }} className='d-flex gap-3 align-items-center px-3 '>
    <div className='position-absolute' style={{ left: '50%', top: '2%' }}>
      <img src={leaf} className='leaf' alt='leaf'/>
    </div>
    <div className='position-absolute' style={{ left: '40%', bottom: '2%' }}>
  <img src={leaf} className='leaf' alt='leaf' />
</div>
    <div className='py-3'>
      <h1 className='text-white pb-0 mb-0' style={{fontSize:'12px', fontWeight:'700', lineHeight:'15px'}}>VIRTUE'S SHOP BRINGS THE MARKET TO YOU!</h1>
      <p className='text-white pt-2 pb-0 my-0' style={{ fontWeight: '500',fontSize:'9.5px',lineHeight:'10px'  }}>Our goal is to get you to fill up your home as fast and as easy as possible. When you think of a fresh food item, you do not need to move to get it.</p>
    </div>
    <Image fluid src={basket} alt='food baskets' className='' />
  </Card.ImgOverlay>
</Card> 
</div>
      </Carousel.Item>
     
     
    </Carousel>

</section>

<section className=' px-0 px-xl-4 pb-3'>
    <Row>
      <Col xs={4}>
      <Card style={{ borderRadius: '0px', border: 'none', cursor:'pointer'  }} className='h-100' onClick={()=> navigate('/foodbundles')}>
  <Card.Img src={foodBundles} alt='Subscription Box - Fresh Food Delivery' style={{height:'90px'}}  />

  </Card>
      </Col>
      <Col xs={4}>
      <Card style={{ borderRadius: '0px', border: 'none' }} className='h-100' onClick={()=> navigate('/vouchers')}>
  <Card.Img src={voucherMobile} alt='Vouchers - Fresh Food Delivery' style={{height:'90px'}}  />
  

  </Card>
      </Col>
      <Col xs={4}>
      <Card style={{ borderRadius: '0px', border: 'none' }} className='h-100' >
  <Card.Img src={premium} alt='Premium Food Boxes - Fresh Food Delivery' style={{height:'90px'}}  />
  
  <Card.ImgOverlay style={{  height:'100%'}} className='d-flex justify-content-center align-items-center' > 
<div style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'24px', fontWeight:'500', fontSize:'6px', width:'70px'}} className='px-2'> COMING SOON!!!</div>
  </Card.ImgOverlay>
  </Card>
      </Col>
    </Row>
  </section>

{loading ? <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div> : <>

     
  <section className='px-1 pb-5'>
        <div className="category-container mb-3">
        {allCategories.map((category) => (
      <div
        key={category.id}
        className={`category-item ${activeCategories.categoryName === category.categoryName ? 'active' : ''}`}
        onClick={() => handleCategoryChange(category)}
      >
        {category.categoryName}
      </div>
    ))}

</div>

    {filteredCategories?.length > 0 ? ( <div className='' > 
    <InfiniteScroll 
      dataLength={products.length}
      next={fetchMoreProducts}
      hasMore={hasMore}
      loader={loadingMore && <div className='justify-content-center align-items-center text-center' >
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>}
      
    >
      <Row className=''style={marginBottomStyle}>

{filteredCategories?.map(product => {

const foundInCart = cartItems.find(item => item.id === product.id);
const productQuantity = foundInCart ? foundInCart.quantity : 0; 
const displayProduct = { ...product, quantity: productQuantity };
const isOutOfStock = displayProduct?.stockLevel === "out_of_stock" || displayProduct?.quantityAvailable === 0;

return (
 <Col xs={6} md={3} className='mb-3 mb-md-4'  key={displayProduct?.id} >
   {isOutOfStock ?   
<Card className='border-0 h-100' style={{boxShadow: '0px 4px 4px 0px #00000040'
}} >
        <div className='position-relative h-100'>
        <LazyLoad height={200} offset={100} placeholder={<div style={{ height: '200px', backgroundColor: '#f0f0f0' }} />}>
        <Card.Img src={displayProduct?.productImage} style={{borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px', width: '100%', 
    height: '100%',maxHeight:'200px', objectFit:'cover' }} alt={displayProduct?.productName}/>
        
        </LazyLoad><Card.ImgOverlay style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', height:'100%',maxHeight:'250px',}} onClick={() => handleShowProductModal(displayProduct)} > <div className=' bg-danger text-center px-2 position-absolute my-1 my-md-0' style={{ padding:'', borderRadius:'100px',boxShadow:'0px 1px 2px 0px #0000001A', top:'5%', right:'5%'}} >

<span  style={{ color:'#ffffff', fontSize:"11px", fontWeight:'400', }}>
OUT OF STOCK
</span>

                             
</div></Card.ImgOverlay>
       
   
                                      
        </div>
       
        
        < Card.Body className='px-2 pt-1 pb-2'>

        <h5 style={{
    color: 'rgba(53, 53, 66, 0.6)',
    fontWeight: '700',
    minHeight: '2.3rem',
    height:'auto', 
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start'
}} className='mt-0 pt-0 mb-0'>
    {displayProduct?.productName}
</h5>

            
          
           <div className='d-flex justify-content-between align-items-center'>
           <div className='d-flex gap-2 align-items-center'>
           <h5 style={{ fontFamily: 'Outfit', color: '#353542', fontWeight: '600' }} className='my-auto'>
  {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' })
    .format(displayProduct?.price)
    .replace('.00', '')}
</h5>




                   
{displayProduct?.OldPrice && (<h5 className='my-auto' style={{color:'#dfdcdc',textDecoration:'line-through', fontSize:'12px', fontFamily:'Outfit', fontWeight:'500',}}>   {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' })
    .format(displayProduct?.OldPrice)
    .replace('.00', '')}</h5>)}
                   

               </div>

               
                  <i
                  onClick={() => toggleFill(displayProduct?.id)}
                  className={`bi ${filledHearts[displayProduct?.id] ? 'bi-heart-fill' : 'bi-heart'}`}

                  style={{
                    fontSize: '1rem',
                    color: filledHearts[displayProduct?.id] ? '#469B12' : 'black', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }}
                ></i> 
            
         
              
               
           </div>
</Card.Body>
         
         
       
      </Card> :  
 <Card className='border-0 h-100' style={{boxShadow:'0px 1px 2px 0px #0000001A', borderRadius:'8px'}}>
   <div className='position-relative mb-1 h-100'>
   <LazyLoad height={200} offset={100} placeholder={<div style={{ height: '200px', backgroundColor: '#f0f0f0' }} />}>
   <Card.Img src={displayProduct?.productImage} onClick={() => handleShowProductModal(displayProduct)} style={{borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px', width: '100%', 
    height: '100%',maxHeight:'200px', objectFit:'cover' }} alt={displayProduct?.productName}/> 
    </LazyLoad>
  {displayProduct?.inSeason && <div className='bg-success position-absolute my-1 my-md-0 text-center px-2' style={{top:'5%', left:'5%', padding:'', borderRadius:'100px',boxShadow:'0px 1px 2px 0px #0000001A'}} >

<span  style={{ color:'#ffffff', fontSize:"11px", fontWeight:'400', }}>
{displayProduct?.inSeason ? 'IN SEASON' : 'OFF SEASON' }
</span>

                             
</div>}
   <div className='position-absolute' style={{top:'5%', right:'5%'}}>
   <div className=' pt-1 d-flex text-center align-items-center justify-content-center px-1  bg-white' style={{borderRadius:'15px', color:'#344054', boxShadow: "0px 1px 2px 0px #0000001A"}}  onClick={() => toggleFill(displayProduct?.id)}>  
      
        <i
                 
                  className={`bi ${filledHearts[displayProduct?.id] ? 'bi-heart-fill' : 'bi-heart'}`}

                  style={{
                    fontSize: '1rem',
                    color: filledHearts[displayProduct?.id] ? '#469B12' : 'black', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }}
                ></i>
</div>
 
   </div>
   
  
                                 
   </div>
  
   
   < Card.Body className='px-2 pt-1 pb-2'>

        <h5 style={{
    color: 'rgba(53, 53, 66, 0.6)',
    fontWeight: '700',
    minHeight: '2.3rem',
    height:'auto', 
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start'
}} className='mt-0 pt-0 mb-0'>
    {displayProduct?.productName}
</h5>

            
           <div className='d-flex justify-content-between align-items-center'>
           <div className='d-flex gap-2 align-items-center'>
           <h5 style={{ fontFamily: 'Outfit', color: '#353542', fontWeight: '600' }} className='my-auto'>
  {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' })
    .format(displayProduct?.price)
    .replace('.00', '')}
</h5>




                   
{displayProduct?.OldPrice && (<h5 className='my-auto' style={{color:'#dfdcdc',textDecoration:'line-through', fontSize:'12px', fontFamily:'Outfit', fontWeight:'500',}}>   {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' })
    .format(displayProduct?.OldPrice)
    .replace('.00', '')}</h5>)}
                   
                   

               </div>
                
               {displayProduct.quantity === 0 ? (
       <Button  onClick={() => handleIncreaseQuantity(displayProduct?.id, 1, true)} className='d-flex text-center justify-content-center align-items-center px-2' style={{height:'30px'}}>ADD</Button>
     ) : (
       <div className="btn-group btn-group-sm gap-1" >
         <Button onClick={() => handleDecreaseQuantity(displayProduct.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'1.4rem', height:'30px'}}>-</Button>
         <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'1.5rem', height:'30px', fontWeight:'600'}}>{displayProduct.quantity}</Button>
         <Button onClick={() => handleIncreaseQuantity(displayProduct.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'1.4rem', height:'30px'}}>+</Button>
       </div>
     )}
         
              
               
           </div>
</Card.Body>
         
    
    
  
 </Card>
}
 </Col>
)})}
</Row>
    </InfiniteScroll>       
      
     </div> 
     ): <div className="text-center fw-medium" style={{ padding: "150px 100px" }}>No products available under this category</div>}

     
        </section></>}
        </div>
     
      
        </div>): ( <div>
           <div className='px-3 px-xxl-5 mx-lg-5 product-page'>



<section className=' px-0 px-xl-4 pt-2'>
<div className='custom-carousel-container'>
<Carousel controls={true} indicators={true} interval={isPaused ? null : 3000} className='custom-carousel' >
      
      <Carousel.Item>
      <div className='pb-5'>
        <img
          className="d-block w-100 banner-image"
          src={immediate}
          alt="Immediate grocery delivery" style={{ borderRadius: '20px', border: 'none'}} 
          onClick={handleImageClick}
        />
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='pb-5'>
      <img
          className="d-block w-100 banner-image"
          src={vsBanner}
          alt="Fresh Food delivery" style={{ borderRadius: '20px', border: 'none'}} 
          onClick={handleImageClick}
          loading="lazy"
        />
   
</div>
      </Carousel.Item>
     
    </Carousel>
    </div>


  </section>
  <section className=' px-0 px-xl-4 pb-3 pt-0'>
    <Row className=''>
      <Col xs={4}>
      <Card style={{ borderRadius: '0px', border: 'none', cursor:'pointer'  }} className='px-3 box-card' onClick={()=> navigate('/foodbundles')}>
  <Card.Img src={subBox} alt='Subscription Box - Fresh Food Delivery' style={{ borderRadius: '0px', border: 'none', height:'100%' }}   />
  

  </Card>
      </Col>
      <Col xs={4} className=' px-3'>
      <Card style={{ borderRadius: '0px', border: 'none',}} className='box-card'  onClick={()=> navigate('/vouchers')} >
  <Card.Img src={voucher} alt='Food Vouchers - Fresh Food Delivery'  style={{ borderRadius: '0px', border: 'none', height:'100%' }} />

 
  </Card>
      </Col>
      <Col xs={4} className=' px-3'>
      <Card style={{ borderRadius: '0px', border: 'none'}} className='px-3 box-card'>
  <Card.Img src={premium} alt='Premium Food stuff - Fresh Food Delivery'  style={{ borderRadius: '0px', border: 'none', height:'100%' }} />
  
  <Card.ImgOverlay style={{  height:'100%'}} className='d-flex justify-content-center align-items-center' > 
<div style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'24px', fontWeight:'500', fontSize:'18px', width:'175px'}} className='px-2'> COMING SOON!!!</div>
  </Card.ImgOverlay>
  </Card>
      </Col>
    </Row>
  </section>

  {loading ? <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div> : <>

  <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-root-margin="0px 0px -40%" data-bs-smooth-scroll="true" className="scrollspy-example " tabIndex="0">
  <section className='px-0 px-xl-4 pb-3' >
    <div className='d-flex justify-content-between align-items-center mb-2'>
    <h4 className='' id="scrollspyHeading1">Top Categories</h4>
    <Button variant='outline-secondary' className='' onClick={() => setViewAll(!viewAll)} style={{color:'#344054', borderColor:'#EAECF0', fontWeight:'500'}}>
  {viewAll ? 'Show Less' : 'View All'}
</Button>

    </div>
    {displayCategories?.length > 0 ?
    (<Row className='category-row' >
    {displayCategories?.map((category, index) => (
      <Col xs={4} md={2} key={category?.id} className='mb-3'>
        <Card className='border-0 text-align-center'style={{}} >
          <div style={{height:'130px'}} className='d-flex justify-content-center align-items-center text-center mx-1 mb-2' > <Card.Img src={category?.categoryImage} alt={category?.categoryName} style={{height:'100%', objectFit:'cover', width:'100%', cursor:'pointer',}} className='' onClick={() => filterProducts(category)}/></div>
          <h6
       className='text-decoration-underline text-center mt-1 mt-md-2'
       onClick={() => filterProducts(category)}
       style={{fontWeight:'500', cursor:'pointer', color:'#344054'}}
     >
       {category?.categoryName}
     </h6>
        
        </Card>
      

     
      </Col>
    ))}

    </Row>): <div className="text-center fw-medium" style={{ padding: " 100px" }}>No categories available </div>}

  </section>
  </div>
  {/* {pagination?.currentPage === 1 &&  */}
  <section className='px-0 px-xl-2 py-3 position-relative'>
    <h4 className='mb-2'> Trending Products <img src={fire} alt='fire'/></h4>
   
    
      {trendingProducts.length > 0 ? (
       <div className="px-3">
          <div
            className="scroll-container"
            style={{ scrollSnapType: 'x mandatory', WebkitOverflowScrolling: 'touch' }}
            ref={scrollContainerRef}
          >
            {trendingProducts.map((product, index) => {
              const foundInCart = cartItems.find(item => item.id === product.id);
              const productQuantity = foundInCart ? foundInCart.quantity : 0;
              const displayProduct = { ...product, quantity: productQuantity };
              const isOutOfStock = displayProduct?.stockLevel === 'out_of_stock' || displayProduct?.quantityAvailable === 0;

              return (
                <div
                  key={displayProduct?.id}
                  style={{ flex: '0 0 auto', width: trendingProducts?.length <= 4 ? '25%' : '21%', position: 'relative' }} className='mb-3'
                >
                  {/* <ProductCard displayProduct={displayProduct}
  filledHearts={filledHearts}
  handleShowProductModal={handleShowProductModal}
  toggleFill={toggleFill}
  handleIncreaseQuantity={handleIncreaseQuantity}
  handleDecreaseQuantity ={handleDecreaseQuantity}/> */}
                  <Card className="border-0 h-100 " style={{ boxShadow: '0px 4px 4px 0px #00000040' }}>
                    <div className="position-relative h-100">
                      <Card.Img
                        src={displayProduct?.productImage}
                        onClick={() => handleShowProductModal(displayProduct)}
                        style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', height: '100%', maxHeight: '250px', objectFit: 'cover' }} loading="lazy" alt={displayProduct?.productName}/>
                      {isOutOfStock && (
                        <Card.ImgOverlay
                          style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            height: '100%',
                            maxHeight: '250px',
                          }}
                          onClick={() => handleShowProductModal(displayProduct)}
                        >
                          <div
                            className="bg-danger text-center px-2 position-absolute"
                            style={{
                              borderRadius: '100px',
                              boxShadow: '0px 1px 2px 0px #0000001A',
                              top: '5%',
                              right: '5%',
                            }}
                          >
                            <span
                              style={{
                                color: '#ffffff',
                                fontSize: '11px',
                                fontWeight: '400',
                              }}
                            >
                              OUT OF STOCK
                            </span>
                          </div>
                        </Card.ImgOverlay>
                      )}
                      {!isOutOfStock && displayProduct?.inSeason && (
                        <div className="bg-success position-absolute my-1 my-md-0 text-center px-2" style={{ top: '5%', left: '5%', padding: '', borderRadius: '100px', boxShadow: '0px 1px 2px 0px #0000001A' }}>
                          <span style={{ color: '#ffffff', fontSize: '11px', fontWeight: '400' }}>
                            {displayProduct?.inSeason ? 'IN SEASON' : 'OFF SEASON'}
                          </span>
                        </div>
                      )}
                      {!isOutOfStock &&
                      <div className="position-absolute d-flex flex-column gap-2" style={{ top: '5%', right: '5%' }}>
                        <div className="pt-1 d-flex text-center align-items-center justify-content-center px-1 bg-white" style={{ borderRadius: '15px', color: '#344054', boxShadow: '0px 1px 2px 0px #0000001A' }} onClick={() => toggleFill(displayProduct?.id)}>
                          <i className={`bi ${filledHearts[displayProduct?.id] ? 'bi-heart-fill' : 'bi-heart'}`} style={{ fontSize: '1rem', color: filledHearts[displayProduct?.id] ? '#469B12' : 'black', cursor: 'pointer' }}></i>
                        </div>
                      </div>}
                    </div>
                    <Card.Body className="px-2 pt-1 pb-2">
                      <h5
                        style={{
                          color: 'rgba(53, 53, 66, 0.6)',
                          fontWeight: '700',
                          minHeight: '2.2rem',
                          display: 'flex',
                          alignItems: 'start',
                          justifyContent: 'start',
                        }}
                        className="my-0 py-0"
                      >
                        {displayProduct?.productName}
                      </h5>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-2 align-items-center">
                          <h5 style={{ fontFamily: 'Outfit', color: '#353542', fontWeight: '600' }} className="my-auto">
                            {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(displayProduct?.price)}
                          </h5>
                          {displayProduct?.OldPrice && (
                            <h5 className="my-auto" style={{ color: '#dfdcdc', textDecoration: 'line-through', fontSize: '12px', fontFamily: 'Outfit', fontWeight: '500' }}>
                              {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(displayProduct?.OldPrice)}
                            </h5>
                          )}
                        </div>
                        {isOutOfStock ? <i
                  onClick={() => toggleFill(displayProduct?.id)}
                  className={`bi ${filledHearts[displayProduct?.id] ? 'bi-heart-fill' : 'bi-heart'}`}

                  style={{
                    fontSize: '1.5rem',
                    color: filledHearts[displayProduct?.id] ? '#469B12' : 'black', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }}
                ></i> : <> {displayProduct.quantity === 0 ? (
                  <Button onClick={() => handleIncreaseQuantity(displayProduct?.id, 1, true)} className="d-flex text-center justify-content-center align-items-center px-2" style={{ height: '30px' }}>
                    ADD
                  </Button>
                ) : (
                  <div className="btn-group btn-group-sm gap-1">
                    <Button onClick={() => handleDecreaseQuantity(displayProduct.id)} className="px-1 py-0 d-flex text-center align-items-center justify-content-center" style={{ borderRadius: '3px', borderColor: '#B2BCCA', width: '1.5rem', height: '30px' }}>
                      -
                    </Button>
                    <Button variant="white" className="px-1 py-0 d-flex text-center align-items-center justify-content-center" style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}>
                      {displayProduct.quantity}
                    </Button>
                    <Button onClick={() => handleIncreaseQuantity(displayProduct.id)} className="px-1 py-0 d-flex text-center align-items-center justify-content-center" style={{ borderRadius: '3px', borderColor: '#B2BCCA', width: '1.5rem', height: '30px' }}>
                      +
                    </Button>
                  </div>
                )}</>}
                       
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
         
            <Button variant="primary" className="position-absolute top-50 start-0 translate-middle-y" onClick={() => handleScroll('left')} >
              &lt;
            </Button>
          
         
            <Button variant="primary" className="position-absolute top-50 end-0 translate-middle-y" onClick={() => handleScroll('right')} >
              &gt;
            </Button>
         

        </div>
      ) : (
        <div className="text-center fw-medium" style={{ padding: '100px' }}>
          No trending products available
        </div>
      )}
  
  </section>
  
  <section className='px-0 px-xl-2 py-3'>
    <h4 className='mb-2'>  Products <span style={{color:'#98A2B3'}}>({pagination?.totalProducts})</span></h4>
    { products.length > 0 ?  (
       <Row>
    
     {products.map((product) => {
      const foundInCart = cartItems.find(item => item.id === product.id);
      const productQuantity = foundInCart ? foundInCart.quantity : 0; 
      const displayProduct = { ...product, quantity: productQuantity };
      const isOutOfStock = displayProduct?.stockLevel === "out_of_stock" || displayProduct?.quantityAvailable === 0;

      
      return(
      <Col xs={6} md={3} className='mb-3 mb-md-4'  key={displayProduct?.id} >

   
<Card className="border-0 h-100 " style={{ boxShadow: '0px 4px 4px 0px #00000040' }}>
                    <div className="position-relative h-100">
                      <Card.Img
                        src={displayProduct?.productImage}
                        onClick={() => handleShowProductModal(displayProduct)}
                        style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', height: '100%', maxHeight: '250px', objectFit: 'cover' }} loading="lazy" alt={displayProduct?.productName}
                      />
                      {isOutOfStock && (
                        <Card.ImgOverlay
                          style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            height: '100%',
                            maxHeight: '250px',
                          }}
                          onClick={() => handleShowProductModal(displayProduct)}
                        >
                          <div
                            className="bg-danger text-center px-2 position-absolute"
                            style={{
                              borderRadius: '100px',
                              boxShadow: '0px 1px 2px 0px #0000001A',
                              top: '5%',
                              right: '5%',
                            }}
                          >
                            <span
                              style={{
                                color: '#ffffff',
                                fontSize: '11px',
                                fontWeight: '400',
                              }}
                            >
                              OUT OF STOCK
                            </span>
                          </div>
                        </Card.ImgOverlay>
                      )}
                      {!isOutOfStock && displayProduct?.inSeason && (
                        <div className="bg-success position-absolute my-1 my-md-0 text-center px-2" style={{ top: '5%', left: '5%', padding: '', borderRadius: '100px', boxShadow: '0px 1px 2px 0px #0000001A' }}>
                          <span style={{ color: '#ffffff', fontSize: '11px', fontWeight: '400' }}>
                            {displayProduct?.inSeason ? 'IN SEASON' : 'OFF SEASON'}
                          </span>
                        </div>
                      )}
                      {!isOutOfStock &&
                      <div className="position-absolute d-flex flex-column gap-2" style={{ top: '5%', right: '5%' }}>
                        <div className="pt-1 d-flex text-center align-items-center justify-content-center px-1 bg-white" style={{ borderRadius: '15px', color: '#344054', boxShadow: '0px 1px 2px 0px #0000001A' }} onClick={() => toggleFill(displayProduct?.id)}>
                          <i className={`bi ${filledHearts[displayProduct?.id] ? 'bi-heart-fill' : 'bi-heart'}`} style={{ fontSize: '1rem', color: filledHearts[displayProduct?.id] ? '#469B12' : 'black', cursor: 'pointer' }}></i>
                        </div>
                      </div>}
                    </div>
                    <Card.Body className="px-2 pt-1 pb-2">
                      <h5
                        style={{
                          color: 'rgba(53, 53, 66, 0.6)',
                          fontWeight: '700',
                          minHeight: '2.2rem',
                          display: 'flex',
                          alignItems: 'start',
                          justifyContent: 'start',
                        }}
                        className="my-0 py-0"
                      >
                        {displayProduct?.productName}
                      </h5>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-2 align-items-center">
                          <h5 style={{ fontFamily: 'Outfit', color: '#353542', fontWeight: '600' }} className="my-auto">
                            {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(displayProduct?.price)}
                          </h5>
                          {displayProduct?.OldPrice && (
                            <h5 className="my-auto" style={{ color: '#dfdcdc', textDecoration: 'line-through', fontSize: '12px', fontFamily: 'Outfit', fontWeight: '500' }}>
                              {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(displayProduct?.OldPrice)}
                            </h5>
                          )}
                        </div>
                        {isOutOfStock ? <i
                  onClick={() => toggleFill(displayProduct?.id)}
                  className={`bi ${filledHearts[displayProduct?.id] ? 'bi-heart-fill' : 'bi-heart'}`}

                  style={{
                    fontSize: '1.5rem',
                    color: filledHearts[displayProduct?.id] ? '#469B12' : 'black', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }}
                ></i> : <> {displayProduct.quantity === 0 ? (
                  <Button onClick={() => handleIncreaseQuantity(displayProduct?.id, 1, true)} className="d-flex text-center justify-content-center align-items-center px-2" style={{ height: '30px' }}>
                    ADD
                  </Button>
                ) : (
                  <div className="btn-group btn-group-sm gap-1">
                    <Button onClick={() => handleDecreaseQuantity(displayProduct.id)} className="px-1 py-0 d-flex text-center align-items-center justify-content-center" style={{ borderRadius: '3px', borderColor: '#B2BCCA', width: '1.5rem', height: '30px' }}>
                      -
                    </Button>
                    <Button variant="white" className="px-1 py-0 d-flex text-center align-items-center justify-content-center" style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}>
                      {displayProduct.quantity}
                    </Button>
                    <Button onClick={() => handleIncreaseQuantity(displayProduct.id)} className="px-1 py-0 d-flex text-center align-items-center justify-content-center" style={{ borderRadius: '3px', borderColor: '#B2BCCA', width: '1.5rem', height: '30px' }}>
                      +
                    </Button>
                  </div>
                )}</>}
                       
                      </div>
                    </Card.Body>
                  </Card>
    

      
    
      
      </Col>
     )})}
    </Row>
): <div className="text-center fw-medium" style={{ padding: " 100px" }}>No products available </div>}
   
   
{products.length > 0 && (

<div className='d-flex justify-content-between align-items-center my-4' style={{height:'50px'}}>
  <p style={{fontWeight:'500', color:'#919EAB'}}>{products?.length} results</p>
  {pagination.totalPages > 1 && 
<Pagination className='custom-pagination d-flex gap-2' >
 
<button
  className='btn btn-outline-secondary pb-0 px-2'
  disabled={pagination.currentPage === 1}
    onClick={() => handlePageChange(pagination.currentPage - 1)}
  style={{
    border: pagination.currentPage === 1 ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === 1 ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-left"></i></h6>
</button>
 {/* First Page */}
 <Pagination.Item onClick={() => handlePageChange(1)} active={pagination.currentPage === 1}>
    1
  </Pagination.Item>

  {/* Second Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(2)} active={pagination.currentPage === 2}>
      2
    </Pagination.Item>
  )}

  {/* Ellipsis after Second Page if necessary */}
  {pagination.currentPage > 3 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Current Page (if not first, second, last, or second to last page) */}
  {pagination.currentPage > 2 && pagination.currentPage < pagination.totalPages - 1 && (
    <Pagination.Item active>
      {pagination.currentPage}
    </Pagination.Item>
  )}

  {/* Ellipsis before Last Page if necessary */}
  {pagination.currentPage < pagination.totalPages - 2 && pagination.totalPages > 4 && <Pagination.Ellipsis disabled />}

  {/* Second to Last Page (if more than 4 pages in total) */}
  {pagination.totalPages > 4 && (
    <Pagination.Item onClick={() => handlePageChange(pagination.totalPages - 1)} active={pagination.currentPage === pagination.totalPages - 1}>
      {pagination.totalPages - 1}
    </Pagination.Item>
  )}

  {/* Last Page */}
  <Pagination.Item onClick={() => handlePageChange(pagination.totalPages)} active={pagination.currentPage === pagination.totalPages}>
    {pagination.totalPages}
  </Pagination.Item>


<button
  className='btn btn-outline-secondary pb-0 px-2'
  disabled={pagination.currentPage === pagination.totalPages}
  onClick={() => handlePageChange(pagination.currentPage + 1)}
  style={{
    border: pagination.currentPage === pagination.totalPages ? 'none' : '1px solid #C4CDD5',
    color: pagination.currentPage === pagination.totalPages ? '#C4CDD5' : '#000000',
  }}
>
  <h6><i className="bi bi-chevron-right"></i></h6>
</button>
 
  </Pagination>}

</div>)}

  </section>

  </>}
  <section className='px-0 px-xl-3 pt-3 pb-5 d-flex justify-content-end'>
    <div className='d-block d-md-flex gap-3'>
<div style={{borderTopLeftRadius:'52px', borderBottomLeftRadius:'52px', width:'240px',height:'', border:'1px solid #EDE9EA'}} className='p-1 mb-3'>
<div className="row g-4" >

<div  className="col-12 ">
<div className="d-flex align-items-center gap-3 rounded-3 ">
 <div className="flex-shrink-0">
 <i className="bi bi-whatsapp p-2" style={{border:'1px solid #EDE9EA', borderRadius:'50%'}}></i>
 </div>
 <div className="flex-grow-1">
   <div className="">
   <a href='https://chat.whatsapp.com/LQMW9mgRWN2L3LlO8UXFAo' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none'}}>
     <p className="mb-0" style={{fontWeight:'600',color:'#000000'}}>Chat/Shop on Whatsapp</p>
     <p className="text-truncate text-decoration-underline mb-0" style={{color:'#9CA3AF'}}>+234 816 664 1019 </p>
     </a>
   </div>
 </div>
</div>
</div>

</div>
</div>
<div style={{borderTopLeftRadius:'52px', borderBottomLeftRadius:'52px', width:'200px',height:'', border:'1px solid #EDE9EA'}} className='p-1 mb-3'>
<div className="row g-4" >

<div  className="col-12 ">
<div className="d-flex align-items-center gap-3 rounded-3 ">
 <div className="flex-shrink-0">
 <i className="bi bi-whatsapp p-2" style={{border:'1px solid #EDE9EA', borderRadius:'50%'}}></i>
 </div>
 <div className="flex-grow-1">
   <div className="">
   <a href="tel:+2349167682871" className=" text-white text-decoration-none">
     <p className="mb-0 text-dark" style={{fontWeight:'600'}}>Call us on</p>
     <p className="text-truncate text-decoration-underline mb-0" style={{color:'#9CA3AF'}}>+234 9167682871</p>
     </a>
   </div>
 </div>
</div>
</div>

</div>
</div>

</div></section>
</div>

</div>)}
    

    
     
    

     




    </div>
  )
}

export default Home